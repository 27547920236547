import Tippy from 'tippy.js';
import LazyLoad from 'vanilla-lazyload';

import ServerEventsManager from '../helpers/server-events-manager';
import { SELECTION_UPDATED } from '../events/selection-events';
import SelectionManager from '../helpers/selection-manager';
import States from '../helpers/states';

import Component from '../components/component';
import { SEARCH_MANAGER_EVENT } from '../helpers/searchManagerEvent';
import { BASKET_HEADLINE_EVENT } from '../components/basket-headline/basketHeadline';
import SearchManager from '../helpers/searchManager';
import NotificationCenterManager from '../helpers/notificationCenterManager';

import GenericModal from '../components/generic-modal/generic-modal';
import SelectionModal from '../components/selection/modal/selection-modal';
import SelectionSidebar from '../components/selection/sidebar/selection-sidebar';
import SelectionQuantity from '../components/selection/bullet/selection-bullet';
import bindAll from '../lib/common/bind-all';
import delegate from '../lib/dom/delegate';
import { SEARCH_RESULT_SUCCESS } from '../events/search-events';

export default class Page extends Component {
  constructor(page, components, device, dispatcher) {
    super();

    // global dispatcher
    this.dispatcher = dispatcher;
    // global notification center manager
    this.notifier = new NotificationCenterManager();
    // global selection manager
    this.selectionManager = new SelectionManager(this.notifier, this.dispatcher, device);
    // init selection modal
    if (document.querySelector('#selection-modal')) {
      SelectionModal('#selection-modal', this.dispatcher, this.selectionManager, device);
    }
    // init generic modals
    if (document.querySelector('#generic-modal')) {
      GenericModal('#generic-modal', this.dispatcher, device);
    }
    // init selection sidebar
    if (document.querySelector('.selection-sidebar-holder')) {
      SelectionSidebar('.selection-sidebar-holder', this.dispatcher, window.App.SelectionSidebarReadOnly || false);
    }
    // init selection quantity
    document.querySelectorAll('[data-comp="selection-items-count"]').forEach((element) => SelectionQuantity(element, this.dispatcher));
    // init modal center manager
    this.serverEventsManager = new ServerEventsManager(this.dispatcher, this.notifier);
    this.serverEventsManager.init();

    this._device = device;
    this._isMobile = this._device === 'mobile';

    // Dom
    this.dom = {
      component: page,
      page,
      scrollable: this._find('.scrollable'),
      container: this._find('.container'),
      scrollSections: this._findAll('.scroll-section'),
      footer: document.body.querySelector('.ct--footer'),
      sidebar: this._find('.search-sidebar'),
      result: this._find('.search-result'),
    };

    this.Components = components;

    // Deps
    this.SearchManager = null;

    // Props : detect dependencies
    this._hasSearchManager = ['search', 'featured'].includes(
      this.dom.page.getAttribute('data-page'),
    );
    this._hasSidebar = !!((this.dom.page.getAttribute('data-page') == 'search' && this._device == 'desktop') || this.dom.page.getAttribute('data-page') == 'show');
    this._hasBasket = !!this.Components.hasOwnProperty('Basket');
    this._hasBasketHeadline = !!this.Components.hasOwnProperty('BasketHeadline');
    this._hasMap = !!this.Components.hasOwnProperty('Map');

    // Detect Search Manager
    if (this._hasSearchManager) {
      this.SearchManager = new SearchManager(this.dom.page);
    }
    // Detect Sidebar
    if (this._hasSidebar) {
      const elSide =
        this._device == 'desktop'
          ? document.body.querySelector('.search-sidebar')
          : document.body.querySelector('.js-mobile-bottom-navigation');
      this.dom.sidebar = elSide;
      this.footerTop =
        this.dom.footer.getBoundingClientRect().top + window.scrollY;

      if (this._device == 'desktop') this._updateHeightSidebar();
      this._switchSidebarClass();
    }

    window.addEventListener('scroll', this._scrollHandler.bind(this));

    // INITS EVENTSEMITTER
    this._on();

    if (this._hasSearchManager) {
      this.SearchManager.bind(page);
    }

    // Bind All
    bindAll(this, [
      '_update',
      '_scrollHandler',
    ]);

    this._initDataHref();
    this._initializeLazyLoading();
    this._initTooltips();
  }

  /**
   * Bind Events to SearchManager, FilterManager, Map
   */
  _on() {
    // MAP
    if (this._hasMap) {
      // SEARCH MANAGER :: UPDATE MAP
      this.SearchManager.on(SEARCH_MANAGER_EVENT.CLEAR_MAP, () => {
        this.Components['Map'].clear();
      });
      this.SearchManager.on(SEARCH_MANAGER_EVENT.UPDATE_MAP, (elMap) => {
        this.Components['Map'].setMapData(elMap);
      });
      // SEARCH / MAP
      this.SearchManager.on(SEARCH_MANAGER_EVENT.ITEM_MOUSE_ENTER, (id) => {
        this.Components['Map'].highlightMarker(id);
      });
      this.SearchManager.on(SEARCH_MANAGER_EVENT.ITEM_MOUSE_OUT, (id) => {
        this.Components['Map'].unhighlightMarker(id);
      });
      // MAP :: CLICK
      this.Components['Map'].on(SEARCH_MANAGER_EVENT.CLICK, (id) => {
        this.SearchManager.scrollToItem(id);
        this.SearchManager.setItemResultHover(id);
      });
      // MAP :: MOUSE ENTER
      this.Components['Map'].on(SEARCH_MANAGER_EVENT.MOUSE_ENTER, (id) => {
        this.SearchManager.setItemResultHover(id);
      });
      // MAP :: MOUSE LEAVE
      this.Components['Map'].on(SEARCH_MANAGER_EVENT.MOUSE_LEAVE, (id) => {
        this.SearchManager.unsetItemResultHover(id);
      });
    }

    // BASKET HEADLINE
    if (this._hasBasketHeadline) {
      this.Components['BasketHeadline'].on(
        BASKET_HEADLINE_EVENT.CLICKED,
        (item) => {
          if (this.Components.hasOwnProperty(item.name)) {
            this._hideGenericComponents();
            this.Components[item.name].show();
          }
        },
      );
      if (this.Components['BasketHeadline'].getLength() > 1) {
        this.Components['Basket'].hide();
      }
    }

    // BASKET
    if (this._hasBasket) {
      this.dispatcher.on(SELECTION_UPDATED, () => {
        this._toggleBasketView();
      });
      delegate(document.body, '.selection-toggle-button', 'click', (event) => {
        event.preventDefault();
        this.Components['BasketHeadline'].showSidebar();
      });
    }

    // Search results tooltips
    this.dispatcher.on(SEARCH_RESULT_SUCCESS, () => {
      this._initTooltips();
    });
  }

  _toggleBasketView(e) {
    if (e) {
      e.preventDefault();
    }
    // Case if not mobile
    if (this._isMobile === false) {
      this._showSidebarBasket();
    }
    if (this._isMobile === true && this._mobileOptionsOpened === true) {
      this._toggleMobileOptions();
    }
  }

  _toggleMobileOptions(e) {
    if (e) {
      e.preventDefault();
    }
    if (this._mobileOptionsOpened === false) {
      this.dom.mobile.container.classList.add('open');
      this.dom.page.classList.add('opacify');
      this.dom.body.classList.add('no-scroll');
    } else {
      this.dom.mobile.container.classList.remove('open');
      this.dom.page.classList.remove('opacify');
      this.dom.body.classList.remove('no-scroll');
    }

    this._mobileOptionsOpened = !this._mobileOptionsOpened;
  }

  _showSidebarBasket() {
    this._hideGenericComponents();
    this.Components['Basket'].show();
    this.Components['BasketHeadline'].showSidebar();
    if (this.Components['BasketHeadline'].dom.items.length > 1) {
      this.Components['BasketHeadline'].dom.items[0].classList.remove('active');
      this.Components['BasketHeadline'].dom.items[1].classList.add('active');
    }
  }

  _hideSidebarBasket() {
    this.Components['BasketHeadline'].hideSidebar();
  }

  _hideGenericComponents() {
    if (this._hasBasket) {
      this.Components['Basket'].hide();
    }
    if (this._hasMap) {
      this.Components['Map'].hide();
    }
  }

  _initDataHref() {
    window.addEventListener('beforeunload', (event) => {
      document.body.classList.add('loading');
    });

    delegate(document.body, '[data-href]', 'click', (e) => {
      document.body.classList.add('loading');
      e.preventDefault();
      const link = e.delegateTarget.getAttribute('data-href');
      if (link.length > 1) document.location = link;
    });
  }

  _initializeLazyLoading() {
    new LazyLoad({ elements_selector: '.lazy', data_src: 'original-src' });
  }

  _initTooltips() {
    document.querySelectorAll('[data-tooltip]').forEach((element) => {
      const placement = element.getAttribute('data-tooltip-position');
      const navbar = element.closest('[data-component="navbar"]');
      const container = element.closest('main[data-template]');
      const theme = element.dataset.theme ?? '';
      const appendTo = element.hasAttribute('data-tooltip-append')
        ? document.body.querySelector(element.getAttribute('data-tooltip-append'))
        : null;

      new Tippy(element, {
        placement: placement || 'bottom',
        content: element.getAttribute('data-tooltip'),
        appendTo: appendTo || navbar || container || document.body,
        distance: 16,
        theme: theme,
      });
    });
  }

  _retrieveDOM() {
  }

  init() {
  }

  bind() {
  }

  unbind() {
  }

  show() {
  }

  hide() {
  }

  _update(delta, time) {
  }

  _scrollHandler(currentScroll) {
    if (this._hasSidebar || this.dom.sidebar) {
      this._switchSidebarClass();
      if (this._device == 'desktop') this._updateHeightSidebar();
    }
  }

  _switchSidebarClass() {
    const bScreen = window.pageYOffset + window.innerHeight;
    if (bScreen > this.footerTop) {
      this.dom.sidebar.classList.remove('fixed');
    } else {
      this.dom.sidebar.classList.add('fixed');
    }
    // map.resize();
  }

  resize(width = States.width, height = States.height) {
    // Detect Search Manager
    this.footerTop =
      this.dom.footer.getBoundingClientRect().top + window.scrollY;
    if (this._hasSidebar) {
      if (this._device == 'desktop') this._updateHeightSidebar();
      this._switchSidebarClass();
    }
  }

  _updateHeightSidebar() {
    this.dom.sidebar.style.height = window.innerHeight - 80 + 'px';
  }

  _getPersitance() {
    return false;
  }
}
